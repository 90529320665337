import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { ADMIN_ROUTES, FEATURE_FLAG, ICON_NAMES } from '../../../../constants'
import CardTile from '../CardTile'
import { useCheckPolicy } from '../../../../hooks'
import { ASSET_TAGS, BENCHMARKS } from '../../../../policies/admin'
import { useFeatureFlag } from '../../../../redux/slices/appConfig'
// import PropTypes from 'prop-types'

export function useCanViewDataMaintenance () {
  const canViewAssetTags = useCheckPolicy(ASSET_TAGS.viewAssetTagTypes)
  const canViewTransactionTagging = useCheckPolicy('internalOnly')

  const canViewBenchmarks = useCheckPolicy(BENCHMARKS.viewBenchmarks)
  const { active: isBenchmarksActive } = useFeatureFlag(FEATURE_FLAG.BENCHMARKS_SETUP)

  return canViewAssetTags || canViewTransactionTagging || (canViewBenchmarks && isBenchmarksActive)
}

function DataMaintenance () {
  const canViewAssetTags = useCheckPolicy(ASSET_TAGS.viewAssetTagTypes)
  const assetClassificationFeature = useFeatureFlag(FEATURE_FLAG.ASSET_CLASSIFICATION_SETUP)
  const canViewTransactionTagging = useCheckPolicy('internalOnly')

  const canViewBenchmarks = useCheckPolicy(BENCHMARKS.viewBenchmarks)
  const { active: isBenchmarksActive } = useFeatureFlag(FEATURE_FLAG.BENCHMARKS_SETUP)

  return (
    <Container maxWidth='xl' style={{ paddingTop: '1.5rem' }}>
      <Grid container spacing={3}>
        {canViewTransactionTagging ? (
          <Grid item xs={12} md={3}>
            <CardTile
              index={0}
              to='/admin/data-maintenance/transaction-tagging'
              icon={ICON_NAMES.transaction}
              title='Transaction Tagging'
            />
          </Grid>
        ) : null}
        {canViewAssetTags ? (
          <Grid item xs={12} md={3}>
            <CardTile
              index={1}
              to={ADMIN_ROUTES.ASSET_CLASSIFICATIONS_SETUP}
              icon={ICON_NAMES.tag}
              title='Asset Tags'
              disabled={!assetClassificationFeature?.active}
            />
          </Grid>
        ) : null}
        {isBenchmarksActive && canViewBenchmarks ? (
          <Grid item xs={12} md={3}>
            <CardTile
              index={2}
              to={ADMIN_ROUTES.BENCHMARKS}
              icon={ICON_NAMES.benchmarks}
              title='Benchmarks'
            />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  )
}

export default DataMaintenance
